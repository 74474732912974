@import url(https://fonts.googleapis.com/css?family=Ubuntu);



.register-form{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-left:auto;
    margin-right:auto;
}
.LoginForm{
    border: 2px solid red;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-align-items: center;
            align-items: center;
    margin-left:auto;
    margin-right:auto;
    width:50%;
}

.login-form-input{

    margin-top:20px;
    margin-bottom:20px;

    height:30%;
    border: 1px solid #ddd;
    display: block;
    font-size: 1.1em;
    margin-bottom:5px;
    margin-left:auto;
    margin-right:auto;
    padding: 10px;
    text-align: left;
    width: 200px;
    border-radius: 4px;
}
.login-form-button{
    margin-top:30px;
    color:blue;
    margin-bottom:15px;

    -webkit-order: 1px solid #aaa;

            order: 1px solid #aaa;
    background-color: #eee;
    font-size: 1.1em;
    border-radius: 4px;
    padding: 8px 12px;
    font-weight: bold;
}
.card-list {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  list-style-type: none;
}

.card {
  margin: 5px 10px;
  padding: 5px;
  width: 200px;
  border: 1px solid black;
}

.start-learning-button {
  margin: 15px 0;
  padding: 10px 20px;
}

.submit-answer{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-left: auto;
    margin-right: auto;
    

}
.submit-answer *{
    
margin-bottom:20px;
}

* {
  box-sizing: border-box;
  text-align:center;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --font-size: calc(10px + 1vmin);
}

