.LoginForm{
    border: 2px solid red;
    display:flex;
    flex-direction:column;
    align-items: center;
    margin-left:auto;
    margin-right:auto;
    width:50%;
}

.login-form-input{

    margin-top:20px;
    margin-bottom:20px;

    height:30%;
    border: 1px solid #ddd;
    display: block;
    font-size: 1.1em;
    margin-bottom:5px;
    margin-left:auto;
    margin-right:auto;
    padding: 10px;
    text-align: left;
    width: 200px;
    border-radius: 4px;
}
.login-form-button{
    margin-top:30px;
    color:blue;
    margin-bottom:15px;

    order: 1px solid #aaa;
    background-color: #eee;
    font-size: 1.1em;
    border-radius: 4px;
    padding: 8px 12px;
    font-weight: bold;
}